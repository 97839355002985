import * as React from "react";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import { FormHelperText, MenuItem, Select } from "@mui/material";

export default function CustomSelect(props) {
  const {
    helperText,
    placeholder,
    data = [],
    onSelect = () => {},
    error = false,
    defaultValue,
  } = props;
  const [type, setType] = React.useState("");

  React.useEffect(() => {
    if (defaultValue && data.length !== 0) {
      const _type = data.find(
        (x) => x?.toLowerCase() === defaultValue?.toLowerCase()
      );
      setType(_type.toLowerCase());
    }
  }, [defaultValue, data]);

  const handleChange = (event) => {
    if (event.target.value === "") {
      setType("");
    }
    setType(event.target.value);
    onSelect(event.target.value);
  };

  return (
    <FormControl variant="filled" sx={{ minWidth: 120 }} fullWidth>
      <InputLabel id="demo-simple-select-filled-label">
        {placeholder}
      </InputLabel>
      <Select
        labelId="demo-simple-select-filled-label"
        id="demo-simple-select-filled"
        value={type}
        onChange={handleChange}
      >
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        {data
          ? data.map((item) => {
              return (
                <MenuItem key={item} value={item ? item?.toLowerCase() : ""}>
                  {item}
                </MenuItem>
              );
            })
          : null}
      </Select>
      <FormHelperText error={error}>{helperText}</FormHelperText>
    </FormControl>
  );
}
