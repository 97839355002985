import {
  Box,
  Button,
  Card,
  CardContent,
  Stack,
  Typography,
} from "@mui/material";
import TitleWithUnderline from "../titleWithUnderline/titleWithUnderline";

import ExportExcel from "../export/export";

const DisplayCard = ({
  title,
  min,
  value,
  percentage,
  period,
  onClick,
  jsonData,
  exportFileName,
}) => {
  return (
    <Card elevation={0}>
      <CardContent
        p={3}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          bgcolor: "#F2F2F2",
          minHeight: 162,
          flex: 0.5,
        }}
      >
        <Stack direction={"row"} justifyContent={"space-between"}>
          <Box>
            <TitleWithUnderline title={title} />

            <Typography variant={"h4"} fontWeight={"bold"}>
              {value}
            </Typography>
          </Box>

          {jsonData && (
            <ExportExcel data={jsonData} fileName={exportFileName} />
            // <Button onClick={onClick}>
            //   <Download color={"primary"} />
            // </Button>
          )}
        </Stack>

        {/* <Stack direction={"row"} justifyContent={"space-between"}>
          <Typography color={min ? "error.main" : "success.main"}>
            {percentage}
          </Typography>
          <Typography>{period}</Typography>
        </Stack> */}
      </CardContent>
    </Card>
  );
};

export default DisplayCard;
