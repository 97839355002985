import { createSlice } from "@reduxjs/toolkit";
import { reducers } from "../../constants/appConstants";

const initialState = {
  data: [],
};

export const coursesSlice = createSlice({
  name: reducers.courses,
  initialState,
  reducers: {
    saveCourses: (state, action) => {
      state.data = action.payload;
    },
    updateCourses: (state, action) => {
      state.data = [...state.data, action.payload];
    },
    clearCourses: (state) => {
      state.data = [];
    },
  },
});

export const { saveCourses, clearCourses, updateCourses } = coursesSlice.actions;

export default coursesSlice;
