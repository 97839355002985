import {
  Box,
  Divider,
  IconButton,
  InputBase,
  Menu,
  MenuItem,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";
import MuiAppBar from "@mui/material/AppBar";
import { styled, alpha } from "@mui/material/styles";
import { dimens } from "../../constants/appConstants";
import SearchIcon from "@mui/icons-material/Search";
import React from "react";
import Badge from "@mui/material/Badge";
import AccountCircle from "@mui/icons-material/AccountCircle";
import NotificationsIcon from "@mui/icons-material/Notifications";
import Logo from "./logo";
import Image from "../image/image";
import { Hamburger } from "../../assets";
import LetterAvatar from "../letterAvatar/letterAvatar";
import { useUserAuth } from "../../context/userAuthContext";
import { useNavigate } from "react-router-dom";

const StyledAppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  backgroundColor: "white",
  height: dimens.appbar_height,
  justifyContent: "center",
  ...(open && {
    width: `calc(100% - ${dimens.drawer_width}px)`,
    marginLeft: `${dimens.drawer_width}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.black, 0.1),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.black, 0.15),
  },
  color: "black",
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "70ch",
    },
  },
}));

const AppBar = ({ open, handleDrawerOpen }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { logOut } = useUserAuth();
  const navigate = useNavigate();

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogOut = React.useCallback(() => {
    logOut(navigate);
    handleMenuClose();
  }, [logOut, navigate]);

  const handleNotificationClick = () => {
    navigate("/notifications");
  };

  const handleProfileClick = () => {
    handleMenuClose();
    navigate("/profile");
    window.location.reload();
  };

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={Boolean(anchorEl)}
      onBlur={handleMenuClose}
      onClose={handleMenuClose}
    >
      <MenuItem
        disableRipple
        focusRipple={false}
        sx={{
          cursor: "auto",
          "&:hover": {
            backgroundColor: "transparent",
          },
        }}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          handleMenuClose();
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <LetterAvatar small name={"GAMLS ADMIN"} />
          <Divider orientation="vertical" variant="middle" flexItem />
          <Stack ml={2}>
            <Typography variant={"body1"} fontWeight={"bold"}>
              {"GAMLS ADMIN"}
            </Typography>
            <Typography variant={"body1"}>{"admin@gamls.org"}</Typography>
          </Stack>
        </Box>
      </MenuItem>
      <Divider />
      <MenuItem onClick={handleProfileClick}>Profile</MenuItem>
      <Divider />
      <MenuItem
        onClick={handleLogOut}
        sx={{
          color: "primary.main",
          fontWeight: "bold",
        }}
      >
        Log out
      </MenuItem>
    </Menu>
  );

  return (
    <Box>
      <StyledAppBar position="fixed" open={open}>
        <Toolbar>
          <Stack
            direction={"row"}
            sx={{ width: dimens.drawer_width }}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            {open || <Logo />}
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{
                mr: 2,
                ...(open && { display: "none" }),
                borderRadius: 1,
                boxShadow: "0px 3px 6px #00000029",
              }}
            >
              <Image data={{ src: Hamburger, width: 24, height: 24 }} />
            </IconButton>
          </Stack>
          <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Search here"
              inputProps={{ "aria-label": "search" }}
            />
          </Search>
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: { xs: "none", md: "flex", color: "black" } }}>
            <IconButton
              size="large"
              aria-label="show 17 new notifications"
              color="inherit"
              onClick={handleNotificationClick}
            >
              <Badge color="error">
                <NotificationsIcon />
              </Badge>
            </IconButton>
            <IconButton
              size="large"
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              color="inherit"
              onClick={handleProfileMenuOpen}
            >
              <AccountCircle />
            </IconButton>
          </Box>
        </Toolbar>
      </StyledAppBar>
      {renderMenu}
    </Box>
  );
};

export default AppBar;
