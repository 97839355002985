import { Typography } from "@mui/material";
import { Box } from "@mui/system";

const TitleWithUnderline = ({ title }) => (
  <Box>
    <Typography>{title}</Typography>
    <Box
      sx={{
        width: "64px",
        border: "1px solid #E9274E",
        borderRadius: "5px",
      }}
    ></Box>
  </Box>
);

export default TitleWithUnderline;
