import { NavLink } from "react-router-dom";
import { breadcrumbNameMap } from "../../constants/appConstants";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import Image from "../image/image";
import {
  CssBaseline,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";

const ListItemLink = (props) => {
  const { to, open, startIcon, ...other } = props;
  const primary = breadcrumbNameMap[to];

  let icon = null;
  if (open != null) {
    icon = open ? <ExpandLess /> : <ExpandMore />;
  }
  return (
    <li>
      <CssBaseline />
      <ListItem
        button
        component={NavLink}
        to={to}
        {...other}
        end
        sx={{
          "&.active": {
            borderRight: "3px solid black",
            borderRadius: "2px",
            fontWeight: "bold",
          },
        }}
      >
        <ListItemIcon sx={{ display: startIcon || "none" }}>
          <Image data={{ src: startIcon, width: 24, height: 24 }} />
        </ListItemIcon>
        <ListItemText primary={primary} disableTypography />
        {icon}
      </ListItem>
    </li>
  );
};

export default ListItemLink;
