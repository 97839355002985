import React, { Suspense } from "react";
import routes from "./routes/routes";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import Main from "./components/main/main";
import { ThemeProvider } from "@mui/material";
import { theme } from "./constants/appConstants";
import Fallback from "./components/fallback/fallback";
import ErrorBoundary from "./components/errorBoundary/errorBoundary";
import {
  UserAuthContextProvider,
  useUserAuth,
} from "./context/userAuthContext";
import Login from "./pages/login";

function AuthLayout() {
  return (
    <Routes>
      <Route path="/" element={<Login />} />
    </Routes>
  );
}

function AppLayout() {
  const { user } = useUserAuth();
  return !user ? (
    <AuthLayout />
  ) : (
    <Main>
      <Routes>
        {routes.map((route) => {
          return route.sub_routes ? (
            <Route
              key={route.name}
              exact
              path={route.path}
              element={<route.component />}
            >
              {route.sub_routes.map((subRoute, idx) => {
                return (
                  <Route
                    key={idx}
                    exact
                    path={subRoute.path}
                    element={<subRoute.component />}
                  />
                );
              })}
            </Route>
          ) : (
            <Route
              key={route.name}
              exact
              path={route.path}
              element={<route.component />}
            />
          );
        })}
      </Routes>
    </Main>
  );
}

function App() {
  return (
    <ThemeProvider theme={theme}>
      <ErrorBoundary>
        <Suspense fallback={<Fallback />}>
          <UserAuthContextProvider>
            <BrowserRouter initialEntries={["/"]} initialIndex={0}>
              <AppLayout />
            </BrowserRouter>
          </UserAuthContextProvider>
        </Suspense>
      </ErrorBoundary>
    </ThemeProvider>
  );
}

export default App;
