import {
  Avatar,
  Stack,
  Button,
  Typography,
  FormHelperText,
} from "@mui/material";
import { Add } from "@mui/icons-material";
import React, { useCallback } from "react";
import Image from "../image/image";

const ImagePickerButton = ({
  title,
  helperText,
  onImageSelected = () => {},
  error = false,
  accept,
  value,
}) => {
  const inputFileRef = React.useRef();
  const [file, setFile] = React.useState("");

  const handleOnClick = useCallback(() => {
    inputFileRef.current.click();
  }, []);

  const onFileChangeCapture = (e) => {
    if (e.currentTarget.files && e.currentTarget.files[0]) {
      onImageSelected(e.currentTarget.files[0]);
      setFile(e.currentTarget.files[0]);
    }
  };
  return (
    <Stack alignItems={"center"}>
      <Typography>{title}</Typography>
      <Button variant={"flat"} color={"secondary"} onClick={handleOnClick}>
        <input
          style={{ display: "none" }}
          accept={accept || "image/*"}
          type="file"
          ref={inputFileRef}
          onChangeCapture={onFileChangeCapture}
        />
        <Stack direction={"row"} alignItems={"center"} spacing={2}>
          <Avatar sx={{ width: 24, height: 24 }}>
            {file || value ? (
              <Image
                data={{
                  src: value || URL.createObjectURL(file),
                  width: 24,
                  height: 24,
                }}
              />
            ) : (
              <Add />
            )}
          </Avatar>
          <Typography>{file.name || "Filename"}</Typography>
        </Stack>
      </Button>
      <FormHelperText error={error}>{helperText}</FormHelperText>
    </Stack>
  );
};

export default ImagePickerButton;
