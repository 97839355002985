import React from "react";
import ExcelJS from "exceljs";
import { Button } from "@mui/material";
import { Download } from "@mui/icons-material";

const ExportExcel = ({ data, fileName = "gamls_export" }) => {
  function handleExport() {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Sheet1");

    const jsonData = data;

    if (jsonData.length === 0) return;

    // Adding header row
    const header = Object.keys(jsonData[0]);
    worksheet.addRow(header);

    // Adding data rows
    jsonData.forEach((row) => {
      const rowData = [];
      header.forEach((key) => {
        rowData.push(row[key]);
      });
      worksheet.addRow(rowData);
    });

    // Save the workbook
    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `${fileName}.xlsx`;
      a.click();
    });
  }

  return (
    <Button onClick={handleExport}>
      <Download color={"primary"} />
    </Button>
  );

  // <button onClick={handleExport}>Export to Excel</button>;
};

export default ExportExcel;
