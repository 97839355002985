import React, { useRef } from "react";
import MUIRichTextEditor from "mui-rte";
import { FormHelperText } from "@mui/material";
import {
  convertToRaw,
  convertFromRaw,
  convertFromHTML,
  ContentState,
} from "draft-js";
import { stateToHTML } from "draft-js-export-html";

let options = {
  entityStyleFn: (entity) => {
    const entityType = entity.get("type").toLowerCase();

    if (entityType === "image") {
      const data = entity.getData();
      console.log("entity_type", data);
      return {
        element: "iframe",
        attributes: {
          src: data.url,
        },
        style: {
          width: data.width,
          height: data.height,
        },
      };
    }
  },
};

export function RTEtoHtml(data) {
  return stateToHTML(convertFromRaw(JSON.parse(data)));
}

export function htmlToRTE(html) {
  const contentHTML = convertFromHTML(html || "");
  const contentState = ContentState.createFromBlockArray(
    contentHTML.contentBlocks,
    contentHTML.entityMap
  );
  //let contentState = stateFromHTML(html);
  return JSON.stringify(convertToRaw(contentState));
}

const RichTextEditor = (props) => {
  const {
    onSave = () => {},
    error = false,
    helperText,
    value,
    defaultValue,
    controls,
    ...rest
  } = props;

  const ref = useRef(null);

  const handleSave = (data) => {
    const html = RTEtoHtml(data);
    onSave(html);
  };

  return (
    <>
      <MUIRichTextEditor
        controls={
          controls || ["bold", "italic", "underline", "undo", "redo", "save"]
        }
        label={helperText}
        onSave={handleSave}
        inlineToolbar={true}
        error={error}
        //defaultValue={htmlToRTE(input.value)}
        ref={ref}
        defaultValue={htmlToRTE(defaultValue)}
        {...rest}
      />
      <FormHelperText error={error}>
        {"Please click the Save icon to save text"}
      </FormHelperText>
    </>
  );
};

export default RichTextEditor;
