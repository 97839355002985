import { createSlice } from "@reduxjs/toolkit";
import { reducers } from "../../constants/appConstants";

const initialState = {
  all_notifications: [],
};

export const notificationSlice = createSlice({
  name: reducers.user_auth,
  initialState,
  reducers: {
    saveNotifications: (state, action) => {
      state.all_notifications = action.payload;
    },
    clearNotifications: (state) => {
      state.all_notifications = [];
    },
  },
});

export const { clearNotifications, saveNotifications } =
  notificationSlice.actions;

export default notificationSlice;
