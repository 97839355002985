import React from "react";

const Dashboard = React.lazy(() => import("../pages/dashboard/index"));
const Messages = React.lazy(() => import("../pages/messages/index"));
const Profile = React.lazy(() => import("../pages/profile/index"));
const Elections = React.lazy(() => import("../pages/elections/index"));
const Notifications = React.lazy(() => import("../pages/notification/index"));
const UserAuth = React.lazy(() => import("../pages/auth/index"));

const Learning = React.lazy(() => import("../pages/learning/index"));
const Categories = React.lazy(() =>
  import("../pages/learning/subPages/categories")
);
const Courses = React.lazy(() => import("../pages/learning/subPages/courses"));
const Lessons = React.lazy(() => import("../pages/learning/subPages/lessons"));

const Website = React.lazy(() => import("../pages/website/index"));
const Banner = React.lazy(() => import("../pages/website/subPages/banner"));
const Press = React.lazy(() => import("../pages/website/subPages/press"));
const Resources = React.lazy(() =>
  import("../pages/website/subPages/resources")
);
const News = React.lazy(() => import("../pages/website/subPages/news"));
const Events = React.lazy(() => import("../pages/website/subPages/events"));
const Jobs = React.lazy(() => import("../pages/website/subPages/jobs"));

const Analytics = React.lazy(() => import("../pages/analytics/index"));
const GeneralAnalytics = React.lazy(() =>
  import("../pages/analytics/subPages/generalAnalytics")
);
const LearningAnalytics = React.lazy(() =>
  import("../pages/analytics/subPages/learningAnalytics")
);
const EventAnalytics = React.lazy(() =>
  import("../pages/analytics/subPages/eventsAnalytics")
);
const EventInfo = React.lazy(() =>
  import("../pages/analytics/subPages/eventsAnalytics/eventInfo")
);

const websiteSubRoutes = [
  {
    path: "/website/banners",
    name: "Website",
    component: Banner,
    protected: false,
  },
  {
    path: "/website/resources",
    name: "Website",
    component: Resources,
    protected: false,
  },
  {
    path: "/website/news",
    name: "Website",
    component: News,
    protected: false,
  },
  {
    path: "/website/press-release",
    name: "Website",
    component: Press,
    protected: false,
  },
  {
    path: "/website/events",
    name: "Website",
    component: Events,
    protected: false,
  },
  {
    path: "/website/jobs",
    name: "Website",
    component: Jobs,
    protected: false,
  },
];

const learningSubRoutes = [
  {
    path: "/learning/categories",
    name: "Categories",
    component: Categories,
    protected: false,
  },
  {
    path: "/learning/courses",
    name: "Courses",
    component: Courses,
    protected: false,
  },
  {
    path: "/learning/lessons",
    name: "Lessons",
    component: Lessons,
    protected: false,
  },
];

const analyticsSubRoutes = [
  {
    path: "/analytics/general-analytics",
    name: "General Analytics",
    component: GeneralAnalytics,
    protected: false,
  },
  {
    path: "/analytics/learning-analytics",
    name: "Learning Analytics",
    component: LearningAnalytics,
    protected: false,
  },
  {
    path: "/analytics/event-analytics",
    name: "Event Analytics",
    component: EventAnalytics,
    protected: false,
  },
  {
    path: "/analytics/event-analytics/:id",
    name: "Event Info",
    component: EventInfo,
    protected: false,
  },
  // {
  //   path: "/analytics/event-info/:id",
  //   name: "Event Info",
  //   component: EventInfo,
  //   protected: false,
  // },
];

const routes = [
  {
    path: "/",
    name: "Dashboard",
    component: Dashboard,
    protected: false,
  },
  {
    path: "/website",
    name: "Website",
    component: Website,
    sub_routes: websiteSubRoutes,
    protected: false,
  },
  {
    path: "/user-auth",
    name: "User Auth",
    component: UserAuth,
    protected: false,
  },
  {
    path: "/election",
    name: "Election",
    component: Elections,
    protected: false,
  },
  {
    path: "/learning",
    name: "Learning",
    component: Learning,
    // sub_routes: learningSubRoutes,
    protected: false,
  },
  {
    path: "/user-notifications",
    name: "Notifications",
    component: Notifications,
    protected: false,
  },

  {
    path: "/analytics",
    name: "Analytics",
    component: Analytics,
    sub_routes: analyticsSubRoutes,
    protected: false,
  },
  {
    path: "/notifications",
    name: "Notifications",
    component: Messages,
    protected: false,
  },
  {
    path: "/profile",
    name: "Profile",
    component: Profile,
    protected: false,
  },
];

export default routes;
