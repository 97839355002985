import { createContext, useContext, useEffect, useState } from "react";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
} from "firebase/auth";
import { auth } from "../config/firebase-config";
import Swal from "sweetalert2";

const userAuthContext = createContext();

export function UserAuthContextProvider({ children }) {
  const [user, setUser] = useState({});

  function logIn(email, password) {
    return signInWithEmailAndPassword(auth, email, password);
  }
  function signUp(email, password) {
    return createUserWithEmailAndPassword(auth, email, password);
  }
  function logOut(navigate) {
    Swal.fire({
      title: "Log out",
      text: "Are you sure you want to log out?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#FD0113",
      cancelButtonColor: "#000",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        signOut(auth)
          .then(() => {
            Swal.fire({
              title: "Suuccess!",
              text: "Admin is logged out.",
              icon: "success",
              showCancelButton: false,
              confirmButtonColor: "#000",
              confirmButtonText: "OK",
            }).then((result) => {
              console.log(result);
              if (result.isConfirmed) {
                navigate("/");
              }
            });
          })
          .catch(() => {
            Swal.fire({
              title: "Error",
              text: "Logout action was unsuccessful. Kindly try again later.",
              icon: "error",
              showCancelButton: false,
              confirmButtonColor: "#FD0113",
              confirmButtonText: "OK",
            });
          });
      }
    });
  }

  function mapAuthCodeToMessage(authCode) {
    switch (authCode) {
      case "auth/invalid-password":
        return "Invalid email or password";

      case "auth/invalid-email":
        return "Invalid email or password";

      case "auth/network-request-failed":
        return "Network error occured";

      case "auth/user-not-found":
        return "User not found";

      default:
        return "Log in error";
    }
  }

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentuser) => {
      setUser(currentuser);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <userAuthContext.Provider
      value={{ user, logIn, signUp, logOut, mapAuthCodeToMessage }}
    >
      <>{children}</>
    </userAuthContext.Provider>
  );
}

export function useUserAuth() {
  return useContext(userAuthContext);
}
