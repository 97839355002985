class User {
  constructor(obj) {
    obj = obj != null ? obj : {};
    this.firstName = obj.firstName != null ? obj.firstName : "";
    this.lastName = obj.lastName != null ? obj.lastName : "";
    this.gender = obj.gender != null ? obj.gender : "";
    this.email = obj.email != null ? obj.email : "";
    this.activated = obj.activated != null ? obj.activated : false;
    this.memberId = obj.memberId != null ? obj.memberId : "";
    this.role = obj.role != null ? obj.role : "user";
    this.moodleUsername = obj.moodleUsername != null ? obj.moodleUsername : "";
  }
}

export const userConverter = {
  toFirestore: (user) => {
    return {
      firstName: user.firstName,
      lastName: user.lastName,
      gender: user.gender,
      email: user.email,
      activated: user.activated,
      memberId: user.memberId,
      role: user.role,
      moodleUsername: user.moodleUsername,
    };
  },
  fromFirestore: (snapshot, options) => {
    const user = snapshot.data(options);
    return new User({
      firstName: user.firstName,
      lastName: user.lastName,
      gender: user.gender,
      email: user.email,
      activated: user.activated,
      memberId: user.memberId,
      role: user.role,
      moodleUsername: user.moodleUsername,
    });
  },
};

export default User;
