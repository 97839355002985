import * as React from "react";
import DrawerHeader from "./drawerHeader";
import { dimens } from "../../constants/appConstants";
import Logo from "./logo";
import {
  Collapse,
  Drawer,
  List,
  Typography,
  Stack,
  IconButton,
  Box,
} from "@mui/material";
import { ChevronLeft } from "@mui/icons-material";
import {
  Analytics,
  Dashboard,
  Election,
  Learning,
  Notification,
  UserAuth,
  Website,
} from "../../assets";
import ListItemLink from "../listItemLink/listItemLink";

const AppDrawer = ({ open, handleDrawerClose }) => {
  const [listOpen, setListOpen] = React.useState(false);
  const [learningOpen, setLearningOpen] = React.useState(false);
  const [analyticsOpen, setAnalyticsOpen] = React.useState(false);

  React.useEffect(() => {}, [listOpen, learningOpen, analyticsOpen]);

  const handleClick = React.useCallback(
    (e) => {
      e.preventDefault();
      setListOpen(!listOpen);
      setLearningOpen(false);
      setAnalyticsOpen(false);
    },
    [listOpen]
  );

  const handleLearningClick = React.useCallback(
    (e) => {
      e.preventDefault();
      setLearningOpen(!learningOpen);
      setAnalyticsOpen(false);
      setListOpen(false);
    },
    [learningOpen]
  );

  const handleAnalyticsClick = React.useCallback(
    (e) => {
      e.preventDefault();
      setAnalyticsOpen(!analyticsOpen);
      setLearningOpen(false);
      setListOpen(false);
    },
    [analyticsOpen]
  );

  return (
    <Drawer
      color={"primary.dark"}
      sx={{
        width: dimens.drawer_width,
        maxHeight: "100%",
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: dimens.drawer_width,
          bgcolor: "primary.light",
          boxSizing: "border-box",
          border: "none",
        },
      }}
      variant="persistent"
      anchor="left"
      open={open}
    >
      <DrawerHeader sx={{ boxShadow: "0px 0px 10px 5px rgb(0 0 0 / 20%)" }}>
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={open ? "space-between" : "flex-end"}
          flex={1}
        >
          {open && <Logo />}
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeft />
          </IconButton>
        </Stack>
      </DrawerHeader>

      <Box
        sx={{
          overflow: "auto",
        }}
        component="nav"
        aria-label="routes"
        flexGrow={1}
      >
        <List>
          <ListItemLink to="/" startIcon={Dashboard} />
          <ListItemLink
            to="/website"
            open={listOpen}
            onClick={(e) => handleClick(e)}
            startIcon={Website}
          />
          <Collapse component="li" in={listOpen} timeout="auto" unmountOnExit>
            <List disablePadding sx={{ paddingLeft: 7, color: "#F30813" }}>
              <ListItemLink to="/website/banners" />
              <ListItemLink to="/website/resources" />
              <ListItemLink to="/website/news" />
              <ListItemLink to="/website/press-release" />
              <ListItemLink to="/website/events" />
              <ListItemLink to="/website/jobs" />
            </List>
          </Collapse>

          {/* <ListItemLink
            to="/learning"
            open={learningOpen}
            onClick={(e) => handleLearningClick(e)}
            startIcon={Learning}
          /> */}
          {/* <Collapse
            component="li"
            in={learningOpen}
            timeout="auto"
            unmountOnExit
          >
            <List disablePadding sx={{ paddingLeft: 7, color: "#F30813" }}>
              <ListItemLink to="/learning/categories" />
              <ListItemLink to="/learning/courses" />
              <ListItemLink to="/learning/lessons" />
            </List>
          </Collapse> */}

          {/* <ListItemLink to="/election" startIcon={Election} /> */}
          <ListItemLink to="/user-auth" startIcon={UserAuth} />
          <ListItemLink to="/user-notifications" startIcon={Notification} />
          <ListItemLink to="/learning" startIcon={Learning} />
          <ListItemLink
            to="/analytics"
            startIcon={Analytics}
            open={analyticsOpen}
            onClick={(e) => handleAnalyticsClick(e)}
          />
          <Collapse
            component="li"
            in={analyticsOpen}
            timeout="auto"
            unmountOnExit
          >
            <List disablePadding sx={{ paddingLeft: 7, color: "#F30813" }}>
              <ListItemLink to="/analytics/general-analytics" />
              {/* <ListItemLink to="/analytics/learning-analytics" /> */}
              <ListItemLink to="/analytics/event-analytics" />
            </List>
          </Collapse>
        </List>
      </Box>

      <Box py={6} px={3}>
        <Typography>©2024 GAMLS</Typography>
      </Box>
    </Drawer>
  );
};

export default AppDrawer;

//
