import { Box, CssBaseline, Stack, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { dimens } from "../../constants/appConstants";
import AppBar from "../drawer/appbar";
import AppDrawer from "../drawer/appDrawer";
import DrawerHeader from "../drawer/drawerHeader";
import React, { useEffect } from "react";
import Breadcrumb from "../breadCrumb/breadCrumb";
import { useLocation } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, getUser } from "../../config/firebase-config";
import { useDispatch } from "react-redux";

const StyledMain = styled("main", {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${dimens.drawer_width}px`,
  ...(open && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

const Main = ({ children }) => {
  const [open, setOpen] = React.useState(true);
  const location = useLocation();
  const pathnames = location.pathname.split("/").filter((x) => x);
  const [user] = useAuthState(auth);
  const dispatch = useDispatch();

  useEffect(() => {
    if (user) {
      // console.log(user);
      getUser(dispatch);
    }
  }, [user, getUser, dispatch]);
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Stack direction={"row"}>
      <CssBaseline />
      <AppBar open={open} handleDrawerOpen={handleDrawerOpen} />
      <AppDrawer open={open} handleDrawerClose={handleDrawerClose} />
      <StyledMain open={open}>
        <DrawerHeader />

        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          spacing={2}
          sx={{ borderBottom: "1px solid #cecece" }}
          alignItems={"center"}
        >
          <Typography
            fontWeight={"bold"}
            variant={"h5"}
            sx={{
              borderBottom: "1px solid #F30813",
              float: "left",
              textTransform: "capitalize",
            }}
          >
            {/* {JSON.stringify(user)} */}
            {pathnames[1] ? pathnames[1] : pathnames[0] || "Dashboard"}
          </Typography>

          <Breadcrumb pathnames={pathnames} />
        </Stack>
        <Box py={4} flex={1}>
          {children}
        </Box>
      </StyledMain>
    </Stack>
  );
};

export default Main;
