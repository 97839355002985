import { LockOutlined, PersonOutlineOutlined } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { HeroBg } from "../../assets";
import { TransitionAlert } from "../../components";
import { useUserAuth } from "../../context/userAuthContext";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const { logIn, mapAuthCodeToMessage } = useUserAuth();
  const navigate = useNavigate();

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      setError("");
      try {
        await logIn(email, password);
        navigate("/");
      } catch (err) {
        setError(mapAuthCodeToMessage(err.code));
      }
    },
    [email, logIn, mapAuthCodeToMessage, navigate, password]
  );

  const handleForgotClick = useCallback(() => {
    import("../../config/firebase-config").then((config) =>
      config.sendPasswordReset("admin@gamls.org")
    );
  }, []);

  return (
    <Container
      maxWidth
      flex={1}
      sx={{
        backgroundImage: `url(${HeroBg})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
        display: "flex",
        flexdirection: "column",
        alignItems: "center",
        width: "100%",
        minHeight: "100vh",
      }}
    >
      <Card>
        <CardContent>
          <Stack
            flex={1}
            component={"form"}
            spacing={3}
            px={8}
            py={2}
            onSubmit={handleSubmit}
          >
            <Typography fontWeight={"bold"} variant={"h5"}>
              Login as an Admin
            </Typography>
            <Typography>
              Please your username and password to log in.
            </Typography>

            <Box>
              <TransitionAlert
                message={error}
                severity={"error"}
                onClose={() => {
                  setError("");
                }}
              />
            </Box>
            <TextField
              variant="standard"
              label={"Email"}
              type={"email"}
              onChange={(e) => setEmail(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PersonOutlineOutlined />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              variant="standard"
              label={"Password"}
              type={"password"}
              onChange={(e) => setPassword(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LockOutlined />
                  </InputAdornment>
                ),
              }}
            />
            <Box>
              <Button
                variant={"flat"}
                color={"secondary"}
                onClick={handleForgotClick}
              >
                Forgotten password
              </Button>
            </Box>
            <Box>
              <Button
                variant={"contained"}
                color={"primary"}
                type={"submit"}
                sx={{ fontWeight: "bold" }}
              >
                Login
              </Button>
            </Box>
          </Stack>
        </CardContent>
      </Card>
    </Container>
  );
};

export default Login;
