import { createTheme } from "@mui/material";
import {
  Analytics,
  Dashboard,
  Election,
  Learning,
  Notification,
  UserAuth,
  Website,
} from "../assets";

const dimens = {
  appbar_height: 100,
  drawer_width: 280,
};

const theme = createTheme({
  palette: {
    primary: {
      main: "#FF0000",
      light: "#F7E4E4",
      dark: "#FF0000",
    },
    secondary: {
      main: "#000000",
      light: "#CCCCCC",
    },
  },
  shape: {
    borderRadius: 10,
  },
  components: {
    MuiButton: {
      variants: [
        {
          props: { variant: "contained" },
          style: {
            backgroundColor: "#FF0000",
            color: "white",
            borderRadius: 20,
            boxShadow: "none",
            textTransform: "inherit",
            padding: "8px 40px",
          },
        },
        {
          props: { variant: "flat" },
          style: {
            color: "#FF0000",
            borderRadius: 20,
            textTransform: "inherit",
          },
        },
        {
          props: { variant: "flat", color: "secondary" },
          style: {
            color: "#4E799F",
            borderRadius: 20,
            textTransform: "inherit",
            fontSize: "13px",
          },
        },
        {
          props: { variant: "contained", color: "secondary" },
          style: {
            backgroundColor: "#000000",
            color: "white",
            borderRadius: 20,
            boxShadow: "none",
            textTransform: "inherit",
          },
        },
        {
          props: { variant: "contained", color: "info" },
          style: {
            backgroundColor: "#CCCCCC",
            color: "#000000",
            borderRadius: 20,
            boxShadow: "none",
            textTransform: "inherit",
            "&:hover": {
              backgroundColor: "#CCCCCC",
              color: "#000000",
            },
          },
        },
      ],
    },
    MuiCheckbox: {
      styleOverrides: {
        colorPrimary: {
          "&.Mui-checked": {
            color: "#000000",
          },
          "&.MuiCheckbox-indeterminate": {
            color: "#000000",
          },
        },
      },
    },
  },
  overrides: {
    MUIRichTextEditor: {
      root: {},
      editor: {
        borderRadius: "0px 0px 10px 10px",
        backgroundColor: "#EFEFEF",
        padding: 10,
        height: "140px",
        maxHeight: "200px",
        overflow: "auto",
      },
    },
  },
});

const chartData = (categories, data) => {
  return {
    options: {
      fill: {
        colors: ["#E9274E"],
      },
      plotOptions: {
        bar: {
          horizontal: false,
          borderRadius: 0,
        },
      },
      xaxis: {
        categories,
      },
    },

    series: [
      {
        data,
      },
    ],
  };
};
const getYears = () => {
  // Get the current year
  var currentYear = new Date().getFullYear();

  var yearsArray = [];

  for (var year = 2020; year <= currentYear; year++) {
    yearsArray.push(year.toString());
  }

  return yearsArray;
};

const drawerList = [
  { name: "Dashboard", icon: Dashboard },
  {
    name: "Website",
    icon: Website,
    sub_list: [
      "Banners",
      "Resources",
      "News",
      "Press Release",
      "Events",
      "Jobs",
    ],
  },
  { name: "Learning", icon: Learning },
  { name: "Election", icon: Election },
  { name: "User Auth", icon: UserAuth },
  { name: "Notifications", icon: Notification },
  { name: "Analytics", icon: Analytics },
];

const styles = {
  card_style: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    bgcolor: "#F2F2F2",
  },
};

const breadcrumbNameMap = {
  "/": "Dashboard",
  "/website": "Website",
  "/website/banners": "Banners",
  "/website/resources": "Resources",
  "/website/news": "News",
  "/website/press-release": "Press release",
  "/website/events": "Events",
  "/website/jobs": "Jobs",
  "/learning": "Learning",
  "/learning/courses": "Courses",
  "/learning/categories": "Categories",
  "/learning/lessons": "Lessons",
  "/election": "Election",
  "/user-auth": "User Auth",
  "/user-notifications": "User Notifications",
  "/analytics": "Analytics",
  "/analytics/general-analytics": "General Analytics",
  "/analytics/learning-analytics": "Learning Analytics",
  "/analytics/event-analytics": "Event Analytics",
  "/analytics/event-analytics/:id": "Event Info",
  "/notifications": "Notifications",
  "/profile": "Profile",
};

const reducers = {
  post: "post",
  read: "read",
  resource: "resources",
  job: "jobs",
  banner: "banner",
  news: "news",
  event: "events",
  press: "press",
  user_auth: "auth",
  notifications: "notifications",
  elections: "elections",
  questions: "questions",
  categories: "categories",
  courses: "courses",
  lessons: "lessons",
};

const actionIcons = {
  VIEW: "view",
  ADD_USER: "add-user",
  EDIT: "edit",
  DELETE: "delete",
  FILTER: "filter",
};

const regions = [
  "Ahafo Region",
  "Ashanti Region",
  "Bono-East Region",
  "Brong Ahafo Region",
  "Central Region",
  "Eastern Region",
  "Greater Accra Region",
  "North-East Region",
  "Northern Region",
  "Oti Region",
  "Savannah Region",
  "Upper-East Region",
  "Upper-West Region",
  "Western Region",
  "Western-North Region",
  "Volta Region",
];
const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const jobQualification = [
  "Basic",
  "Junior",
  "Secondary",
  "Tertiary",
  "Masters",
  "Doctorate",
];

const experienceLevel = [
  "Beginner",
  "Novice",
  "Intermediate",
  "Advanced",
  "Specialist",
];

const experienceLength = ["Less than 1 year", "1-3", "3-5", "5-10", "10+"];

const jobType = ["Part time", "Full time", "Contract"];

export {
  dimens,
  theme,
  chartData,
  drawerList,
  styles,
  breadcrumbNameMap,
  reducers,
  actionIcons,
  regions,
  experienceLevel,
  jobQualification,
  experienceLength,
  jobType,
  months,
  getYears,
};
